<template>
  <div class="entry-content">
    <router-link to="/users" class="back-button-wrapper">
      <h1 class="back">
        <img
          class="align-baseline"
          src="/img/icons/arrow-left.svg"
          height="15"
          alt="left-arrow"
        />
        Back
      </h1>
    </router-link>

    <div class="row">
      <div class="col-md-6">
        <h1 class="mt-4">User Details</h1>
        <div class="user-details mt-3">
          <div class="row m-0">
            <div class="title">Name:</div>
            <div class="content">{{ user.name || "User Name" }}</div>
          </div>
          <div class="row m-0">
            <div class="title">Email:</div>
            <div class="content">{{ user.email }}</div>
          </div>
          <div class="row m-0">
            <div class="title">Date Joined:</div>
            <div class="content">{{ user.created_at | moment }}</div>
          </div>
          <div class="row m-0">
            <div class="title">Number of pet profiles:</div>
            <div class="content">{{ user.pets.length }}</div>
          </div>
          <div class="row m-0">
            <div class="title">Certified:</div>
            <div class="content">{{ user.certify == 1 ? "Yes" : "No" }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h1 class="mt-4">Bank Details</h1>
        <div class="user-details mt-3">
          <div v-if="user.user_bank_card === null">
            No Bank Details Added
          </div>
          <div v-else>
            <div class="row m-0">
              <div class="title">Account Holder Name:</div>
              <div class="content">{{ user.user_bank_card.accountholder_name || "User Name" }}</div>
            </div>
            <div class="row m-0">
              <div class="title">Bank:</div>
              <div class="content">{{ user.user_bank_card.bank || "User Name" }}</div>
            </div>
            <div class="row m-0">
              <div class="title">Clearing:</div>
              <div class="content">{{ user.user_bank_card.clearing || "User Name"}}</div>
            </div>
            <div class="row m-0">
              <div class="title">Account Number:</div>
              <div class="content">{{ user.user_bank_card.account_number || "User Name" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h1 class="mt-4">Pet Profiles</h1>
    <div class="pet-profile" v-for="pet in user.pets" :key="pet.id">
      <div class="row m-0">
        <div class="title">Name:</div>
        <div class="content">{{ pet.name || "Pet Name" }}</div>
      </div>
      <div class="row m-0">
        <div class="title">Breed 1:</div>
        <div class="content">{{ pet.breed1 }}</div>
      </div>
      <div class="row m-0">
        <div class="title">Breed 2:</div>
        <div class="content">{{ pet.breed2 || "n/a" }}</div>
      </div>
      <div class="row m-0">
        <div class="title">Breed 3:</div>
        <div class="content">{{ pet.breed3 || "n/a" }}</div>
      </div>
      <div class="row m-0">
        <div class="title">Description:</div>
        <div class="content">{{ pet.description || "n/a" }}</div>
      </div>
      <div class="row m-0">
        <div class="title">Allergies:</div>
        <div class="content">{{ pet.allergies || "n/a" }}</div>
      </div>
      <div class="row m-0">
        <div class="title">Social Media 1:</div>
        <div class="content">{{ pet.social_media1 || "n/a" }}</div>
      </div>
      <div class="row m-0">
        <div class="title">Social Media 2:</div>
        <div class="content">{{ pet.social_media2 || "n/a" }}</div>
      </div>
      <div class="row m-0">
        <div class="title">Social Media 3:</div>
        <div class="content">{{ pet.social_media3 || "n/a" }}</div>
      </div>

      <div class="row m-0">
        <div class="title">Date Created:</div>
        <div class="content">{{ pet.created_at | moment }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  props: {
    user: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      users: [],
    };
  },
  methods: {
    getAllUsers: function () {
      axios
        .get(
            "/admin/all-users?access_token=" +
            localStorage.getItem("access_token")
        )
        .then(
          (result) => {
            this.users = result.data.users;
          }, () => {}
        );
    },
    goToUser: function (user) {
      this.$router.push({ name: "userProfile", params: { user: user } });
    },
  },
  filters: {
    moment: function (date) {
      let d = moment(date);
      return d.isValid() ? moment(date).format("MMMM DD, YYYY") : "N/A";
    },
  },
  mounted() {
    this.getAllUsers();
  },
};
</script>

<style lang="scss" scoped>
.entry-content {
  padding: 75px 25px 25px 50px;
}

.user-details {
  .title {
    font-weight: bold;
    margin-right: 7px;
    margin-bottom: 5px;
  }
}

.pet-profile {
  background-color: white;
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 7px;

  .title {
    font-weight: bold;
    margin-right: 7px;
    margin-bottom: 5px;
  }
}
</style>
