<template>
<div class="container-fluid pt-8">
    <div class="row">
      <div class="col-lg-12">
      <div class="form-group mx-auto login-max-width">
      <label class="resource-text m-0" for="newPassword">New Password</label>
      <div class="input-group">
        <input class="form-control border-0" :type="inputTypeNew" id="newPassword"
          placeholder="password"
          v-model="login.newPassword">
        <div class="input-group-prepend m-2">
          <span v-if="!newPasswordShow" class="eye-icon cursor-pointer" @click="hideNewPassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
          <span v-else class="eye-icon cursor-pointer" @click="showNewPassword"><i class="fas fa-eye-slash"></i></span>
        </div>
        </div>
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
      <div class="form-group mx-auto login-max-width">
      <label class="resource-text m-0" for="password_confirmation">Confirm Password</label>
      <div class="input-group">
        <input class="form-control border-0" :type="inputTypeConfirm" id="password_confirmation"
          placeholder="password"
          v-model="login.password_confirmation">
        <div class="input-group-prepend m-2">
          <span v-if="!confirmShow" class="eye-icon cursor-pointer" @click="hideConfirm"><i class="fa fa-eye" aria-hidden="true"></i></span>
          <span v-else class="eye-icon cursor-pointer" @click="showConfirm"><i class="fas fa-eye-slash"></i></span>
        </div>
        </div>
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mt-4">
        <button type="button" class="btn btn-primary px-5 iwu-btn resource-large-text" @click="changePassword">Change Password</button>
        <div>{{response}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import router from "@/router";

  export default {
    name: 'reset-password',
    data: () => {
      return {
        login: {
          password: ''
        },
        newPasswordShow: false,
        confirmShow: false,
        inputTypeNew: 'password',
        inputTypeConfirm: 'password',
        response: '',
        resetToken: '',
        email:''
      }
    },
    created(){
      this.resetToken= this.$route.query.token;
      this.email= this.$route.query.email;
    },
    methods: {
      showNewPassword: function(){
          this.newPasswordShow = false;
          this.inputTypeNew = 'password';
        },
      hideNewPassword: function(){
          this.newPasswordShow = true;
          this.inputTypeNew = 'text';
        },
        showConfirm: function(){
          this.confirmShow = false;
          this.inputTypeConfirm = 'password';
        },
      hideConfirm: function(){
          this.confirmShow = true;
          this.inputTypeConfirm = 'text';
        },

      changePassword: function(){
        if(this.login.newPassword !== this.login.password_confirmation){
          this.$noty.error("New and confirm password aren't the same");
          return;
        }


        const data = {};
        data.email = this.email;
        data.reset_token = this.resetToken;
        data.password = this.login.newPassword;
        data.password_confirmation = this.login.password_confirmation;

        axios({
          method: "POST",
          url:
            "admin/reset-password",
          data: data,
        }).then(
          () => {
            router.push({ path: "/login" });
          },
          (error) => {
            this.$noty.error(error.response.data.message);
          }
        );
    }
  }
}
</script>
<style>

body{
  background-color: #f2f0eb;
}
</style>
