<template>
  <div class="container-fluid pt-8">
    <div class="row">
      <div class="col-lg-12">
        <p class="main-message m-0 mx-auto login-max-width">I Woof U Admin</p>
        <div class="row">
          <div class="col-lg-12 mt-4">
            <div class="form-group mx-auto login-max-width">
              <label class="resource-text m-0" for="email">Email</label>
              <input type="text"
                     class="form-control"
                     id="email"
                     placeholder="example@myemail.com"
                     v-model="login.email" @keyup.enter="$refs.password.focus();">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group mx-auto login-max-width">
              <label class="resource-text m-0" for="password">Password</label>
              <div class="input-group">
                <input class="form-control border-0" :type="inputType" id="password"
                       placeholder="password"
                       v-model="login.password" @keyup.enter="auth" ref="password">
                <div class="input-group-prepend m-2">
                  <span v-if="!passwordShow" class="eye-icon cursor-pointer" @click="hidePassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
                  <span v-else class="eye-icon cursor-pointer" @click="showPassword"><i class="fas fa-eye-slash"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-12 text-center mt-5">
            <button type="button" class="btn btn-primary px-7 iwu-btn resource-large-text" @click="auth">Log In</button>
            <router-link class="d-block mt-4" to="/password-forget">
              <small class="resource-text forgot-password-text">Forgot your password?</small>
            </router-link>
            <div>{{response}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import router from "@/router";

export default {
  name: 'login',
  data() {
    return {
      login: {
        email: '',
        password: ''
      },
      forgot: {},
      response: '',
      passwordShow: false,
      inputType: 'password',
    }
  },
  methods: {
    auth: function () {
      var data = new FormData();
      data.append('email', this.login.email);
      data.append('password', this.login.password);
      this.loader = true;
      axios(
          {
            method: "POST",
            "url": "admin/login",
            "data": data,
            "headers": {
              "content-type": "multipart/form-data",
            }
          }
      ).then(result => {
        this.response = result.data['message'];

        localStorage.setItem('user', JSON.stringify(result.data.user));
        localStorage.setItem('auth', result.data.auth);
        localStorage.setItem('access_token', result.data.auth.access_token);
        router.push({ path: "/" });

      }, () => {
        this.$noty.error("Please check your credentials");
        this.loader = false;
      });
    },
    showPassword: function(){
      this.passwordShow = false;
      this.inputType = 'password';
    },
    hidePassword: function(){
      this.passwordShow = true;
      this.inputType = 'text';
    },
  },
  mounted: function () {
  },
}
</script>
<style>


.form-control{
  background-color:#F8F7F5;
}

.btn.resource-large-text, .forgot-password-text{
  font-weight:300 !important;
}


</style>
