<template>
  <div class="entry-content">
    <div class="d-flex justify-content-between">
      <h1>Payments</h1>
      <div>
        <select class="form-control" @change="filterStatus($event)">
              <option value="">Selected</option>
              <option value="1">Paid</option>
              <option value="2">Cancelled</option>
              <option value="3">Refunded</option>
              <option value="4">No Refund</option>
        </select>
      </div>
    </div>

    <div class="table-header mt-3 pb-2">
      <div class="row">
        <div class="text col-1">Paid By</div>
        <div class="text col-1">Payment To</div>
        <div class="text col-1">Date</div>
        <div class="text col-1">Service Type</div>
        <div class="text col-1">Tax</div>
        <div class="text col-1">Total</div>       
        <div class="text col-2">Cancelled By</div>
        <div class="text col-1">Booking Date</div>
        <div class="text col-1">Cancelled Date</div>
        <div class="text col-2">Status</div>
      </div>
    </div>
    <div class="table-content mb-3">
      <div class="user" v-for="(payment, index) in payments" :index="index" :key="payment.id">
        <div class="row">
          <div class="col-1" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.dog_owner.name}}</div>
          <div class="col-1" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.amount_to.name}}</div>
          <div class="col-1" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.purchased_date}}</div>
          <div class="col-1" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.service_type}}</div>
          <div class="col-1" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.tax_price}}</div>
          <div class="col-1" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.total_price}}</div>
          <div class="col-2" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.cancelled_by}}</div>
          <div class="col-1" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.booking_date}}</div>
          <div class="col-1" @click="goToUser(payment.amount_to, payment.dog_owner)">{{payment.cancelled_date}}</div>
          <div class="col-2">
            <select class="form-control status_item" :indexSelected="payment.status ? payment.status : 0" @change="onChangeStatus($event, payment.id, index)">
              <option :selected= "payment.status == 1" value="1">Paid</option>
              <option :selected= "payment.status == 2" value="2">Cancelled</option>
              <option :selected= "payment.status == 3" value="3">Refunded</option>
              <option :selected= "payment.status == 4" value="4">No Refund</option>
            </select>
          </div>
        </div>
        <div class="divider-line"></div>
      </div>
    </div>
    <pagination
      :total="totalPages"
      @fetchData="getAllPayments"
      v-if="totalPages > 1"
    />

    <modal :show="showChangeStatusModal" @close="showChangeStatusModal = false">
      <div class="d-flex flex-column align-items-center">
        <h2>Are you sure you want to change the payment booking status?</h2>
        <div class="buttons">
          <div class="buttons d-flex">
            <div class="btn btn-secondary mt-3 mr-3" @click="cancelPopupStatus()">
              Cancel
            </div>
            <div
              class="btn btn-danger mt-3 justify-content-center d-flex"
              @click="updateStatus(idPaymentBooking, idStatus)"
              style="width: 200px"
            >
              <div v-if="modal.loading" class="loader"></div>
              <span class="font-weight-bold" v-else>Confirm Update</span>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import pagination from "../components/Pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      totalPages: 1,
      status: null,
      payments: [],
      itemIndexSelectedRow: null,
      itemIndexSelectedOption: null,
      showChangeStatusModal: false,
      idPaymentBooking: Number,
      idStatus: Number,
      modal: {
        type: String,
        title: String,
        section: "",
        language: "",
        question: "",
        answers: "",
        sectionType: "existingSection",
        loading: false,
        errorMessage: "",
        id: Number,
      },
    };
  },
  methods: {

    getAllPayments: function (page) {
      var url = [];  
      if(this.status == null) {
        url = "/admin/payments?page=" + page + "&access_token=" +
          localStorage.getItem("access_token");
      } else {
        url = "/admin/payments?page=" + page + "&status=" + this.status + "&access_token=" +
          localStorage.getItem("access_token");
      }
      axios.get(
        url
      ).then(
        (result) => {
          this.payments = result.data.data;
          this.totalPages = result.data.meta.last_page;
        }, () => {
        }
      );
    },
    goToUser: function (walker, owner) {
      this.$router.push({ name: "paymentProfile", params: { walker: walker,  owner: owner} });
    },
    updateStatus(idPaymentBooking, idStatus) {
      this.modal.loading = true;
      var data = new FormData();
      data.append('status', idStatus);
      axios.post(
          "/admin/booking/change-booking-status/" + idPaymentBooking +
          "?access_token=" +localStorage.getItem("access_token"), data
      ).then(
        (result) => {
            this.$noty.success(result.data.message);
            this.modal.loading = false;
            this.showChangeStatusModal = false;
            document.getElementsByClassName('status_item')[this.itemIndexSelectedRow].setAttribute("indexselected", idStatus);
        }, () => {
          this.modal.loading = false;
          this.showChangeStatusModal = false;
          this.cancelPopupStatus();
        }
      );    
    },
    cancelPopupStatus() {
      this.showChangeStatusModal = false;
      document.getElementsByClassName('status_item')[this.itemIndexSelectedRow].value = this.itemIndexSelectedOption;
    },
    filterStatus(event) {
        this.status = event.target.value;
        this.getAllPayments(1);
    },
    onChangeStatus: function (event, id, itemIndex) {
      this.idPaymentBooking = id;
      this.idStatus = event.target.value;
      this.showChangeStatusModal = true;

      this.itemIndexSelectedRow = itemIndex;
      this.itemIndexSelectedOption = document.getElementsByClassName('status_item')[itemIndex].getAttribute("indexselected");
    },

  },
  filters: {
    moment: function (date) {
      let d = moment(date);
      return d.isValid() ? moment(date).format("MMMM DD, YYYY") : "N/A";
    },
  },
  mounted() {
    this.getAllPayments(1);
  },
};

</script>

<style lang="scss" scoped>
.entry-content {
  padding: 75px 25px 25px 50px;
}

.table-header {
  border-bottom: solid 1px black;

  .text {
    font-weight: bold;
  }
}

.user .row {
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.divider-line {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}
</style>
