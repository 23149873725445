<template>
    <div class="entry-content">
        <h1>Alerts List</h1>

        <div class="allReports">
            <div v-for="report in reports"
                :key="report.id"
                class="card resource bg-white m-3 p-3 cursor-pointer"
                @click="seeReportDetails(report)">
                <div class="showReports">
                    <h2>Report Alert</h2>
                    <p class="time">{{report.created_at | moment }}</p>
                    <p class="whoReported" >{{report.reported_by.name}} has reported an user</p>
                </div>
            </div>
        </div>
        <div class="reportDetails bg-white mt-3 p-3" v-if="show">
            <h2> Report Description </h2>
            <p class="time">{{specificReport.created_at | moment }}</p>
            <p class="whoReported" >{{specificReport.reported_by.name}} has reported {{specificReport.reported_user.name}}</p>
            <h5>Comment by {{specificReport.reported_by.name}}</h5>
            <div v-if="specificReport.offensive_behaviour">
                Offensive Behaviour:
               <div>
                   {{specificReport.offensive_behaviour_explained}}
                </div>
            </div>
            <div v-if="specificReport.payment_scam" class="mt-2">
                Payment Scam
                <div>
                    {{specificReport.payment_scam_explained}}
                </div>
            </div>
            <div v-if="specificReport.scam_account" class="mt-2">
                Scam Account
                <div>
                    {{specificReport.scam_account_explained}}
                </div>
            </div>
            <div v-if="specificReport.other" class="mt-2">
                Other:
                <div>
                    {{specificReport.other_explained}}
                </div>
            </div>
            <div class="userInfo">
                <!--img :src="specificReport.reported_user.image"/ -->
                <!-- <div class="imageDiv">
                    <img v-if="(specificReport.reported_user.image)" :src="appURL + specificReport.reported_user.image" alt="user image"
                        style="width: auto; max-height: 112px;">
                    <img v-else src="../assets/nav-user.png" class="my-3" alt="default image"
                        style="width: auto; max-height: 80px;">
                </div>
                <div class="reportedPersonDiv">
                    <p class="reportedPerson">{{specificReport.reported_user.name}}</p>
                </div> -->
                <div class="mt-4 d-flex flex-row">
                    <button @click="deleteFaq(specificReport.id, 'discard')" class="btn btn-success mr-4">
                        Discard Report
                    </button>
                    <button @click="deleteFaq(specificReport.id, 'report')" class="btn btn-primary mr-4">
                        Report User
                    </button>
                    <button @click="deleteFaq(specificReport.id, 'block')" class="btn btn-danger">
                        Block User
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
  import moment from "moment";
export default {
  components: {},
  filters: {
    moment: function (date) {
    let d = moment(date);
    return d.isValid() ? moment(date).format("MMM D") : "N/A";
    },
  },
  data() {
    return {
      show: false,
      appURL: process.env.VUE_APP_MAIN_URL,
      reports: [],
      specificReport: null,
      data: {
          action: ''
      },
    };
  },
  methods: {
    getAllReports: function () {
      axios
        .get(
            "admin/reports?access_token=" +
            localStorage.getItem("access_token")
        )
        .then(
          (result) => {
            this.reports = result.data.reports.data;
            this.show= true;
            this.specificReport = (this.reports)?this.reports[0]:[];
          }, () => {
          }
        );
    },
    seeReportDetails: function (report) {
        this.show= true;
        this.specificReport = report;
    },
    deleteFaq: function (id, action) {
      this.data.action = action;
      axios
        .post(
            `admin/report-action/${id}?access_token=${localStorage.getItem('access_token')}`, this.data
        )
        .then(
          () => {
                this.$noty.success('Action completed successfully')
                this.getAllReports();

          }, () => {
          }
        );
    },
  },
  mounted() {
    this.getAllReports();
  },
};
</script>

<style scoped>
.entry-content {
  padding: 75px 25px 25px 50px;
}

.allReports{
    float: left;
    width: 30%;
}

.card{
    min-width: 300px !important;
    min-height: 100px !important;
}

.reportDetails{
    float: right;
    width: 60%;
    border-radius: 10px;
}

h2{
    width:80%;
    float: left;
}

.time{
    font-size: 10px;
    width:20%;
    float: right;
    text-align: end;
    padding-right: 5px;
}
.whoReported{
    clear: left;
}
p{
    font-size: 13px;
}


.userInfo{
    display:inline-block;
}

</style>
