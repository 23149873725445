var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          custom_icon: '/img/icons/nav-user.svg',
          path: '/users'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Blocked Users',
          custom_icon: '/img/icons/nav-user.svg',
          path: '/blockedUsers'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Alerts',
          custom_icon: '/img/icons/chat.svg',
          path: '/Alerts'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'FAQ',
          custom_icon: '/img/icons/chat.svg',
          path: '/Faq'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Payments',
          custom_icon: '/img/icons/chat.svg',
          path: '/Payments'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Settings',
          custom_icon: '/img/icons/settings.svg',
          path: '/settings'
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }