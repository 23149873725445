<template>
<div class="container-fluid pt-8">
  <div class="row">
  <div class="col-lg-12">
    <p class="main-message m-0 mx-auto login-max-width">New Password</p>
    <div class="row">
      <div class="col-lg-12 mt-2">
      <div class="form-group mx-auto login-max-width">
      <label class="resource-text m-0" for="oldPassword">Old Password</label>
      <div class="input-group">
        <input class="form-control border-0" :type="inputTypeOld" id="oldPassword"
          placeholder="password"
          v-model="login.oldPassword">
        <div class="input-group-prepend m-2">
          <span v-if="!oldPasswordShow" class="eye-icon cursor-pointer" @click="hideOldPassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
          <span v-else class="eye-icon cursor-pointer" @click="showOldPassword"><i class="fas fa-eye-slash"></i></span>
        </div>
        </div>
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
      <div class="form-group mx-auto login-max-width">
      <label class="resource-text m-0" for="newPassword">New Password</label>
      <div class="input-group">
        <input class="form-control border-0" :type="inputTypeNew" id="newPassword"
          placeholder="password"
          v-model="login.newPassword">
        <div class="input-group-prepend m-2">
          <span v-if="!newPasswordShow" class="eye-icon cursor-pointer" @click="hideNewPassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
          <span v-else class="eye-icon cursor-pointer" @click="showNewPassword"><i class="fas fa-eye-slash"></i></span>
        </div>
        </div>
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
      <div class="form-group mx-auto login-max-width">
      <label class="resource-text m-0" for="password_confirmation">Confirm Password</label>
      <div class="input-group">
        <input class="form-control border-0" :type="inputTypeConfirm" id="password_confirmation"
          placeholder="password"
          v-model="login.password_confirmation">
        <div class="input-group-prepend m-2">
          <span v-if="!confirmShow" class="eye-icon cursor-pointer" @click="hideConfirm"><i class="fa fa-eye" aria-hidden="true"></i></span>
          <span v-else class="eye-icon cursor-pointer" @click="showConfirm"><i class="fas fa-eye-slash"></i></span>
        </div>
        </div>
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-lg-12 text-center mt-4">
        <button type="button" class="btn btn-primary px-5 iwu-btn resource-large-text" @click="changePassword">Change Password</button>
        <div>{{response}}</div>
      </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
  import axios from "axios";
  import router from "@/router";

  export default {
    name: 'reset-password',
    data: () => {
      return {
        login: {
          email: '',
          password: ''
        },
        newPasswordShow: false,
        oldPasswordShow: false,
        confirmShow: false,
        inputTypeOld: 'password',
        inputTypeNew: 'password',
        inputTypeConfirm: 'password',
        response: '',
      }
    },
    methods: {
      showOldPassword: function(){
          this.oldPasswordShow = false;
          this.inputTypeOld = 'password';
        },
      hideOldPassword: function(){
          this.oldPasswordShow = true;
          this.inputTypeOld = 'text';
        },
      showNewPassword: function(){
          this.newPasswordShow = false;
          this.inputTypeNew = 'password';
        },
      hideNewPassword: function(){
          this.newPasswordShow = true;
          this.inputTypeNew = 'text';
        },
        showConfirm: function(){
          this.confirmShow = false;
          this.inputTypeConfirm = 'password';
        },
      hideConfirm: function(){
          this.confirmShow = true;
          this.inputTypeConfirm = 'text';
        },

        changePassword: function(){
          if(this.login.newPassword === this.login.password_confirmation){
            var data = new FormData();
            data.append('old_password', this.login.oldPassword);
            data.append('new_password', this.login.newPassword);
            this.loader = true;

            axios.post("admin/change-password?access_token=" +
            localStorage.getItem("access_token"), data, null)
              .then(result => {
                this.response = result.data['message'];

                localStorage.setItem('user', JSON.stringify(result.data.user));
                localStorage.setItem('auth', result.data.auth);
                localStorage.setItem('access_token', result.data.auth.access_token);
                router.push({ path: "/" });
              }, () => {
              this.$noty.error("Please check your credentials");
              this.loader = false;
            });
          }else{
        this.$noty.error("New and confirm password aren't the same");
      }
    }
  }
}
</script>
<style>

body{
  background-color: #f2f0eb;
}
</style>
