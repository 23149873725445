<template>
  <div class="entry-content">
    <router-link to="/payments" class="back-button-wrapper">
      <h1 class="back">
        <img
          class="align-baseline"
          src="/img/icons/arrow-left.svg"
          height="15"
          alt="left-arrow"
        />
        Back
      </h1>
    </router-link>

    <div class="row">
      <div class="col-md-6">
        <h1 class="mt-4">Walker Details</h1>
        <div class="user-details mt-3">
          <div class="row m-0">
            <div class="title">Name:</div>
            <div class="content">{{ walker.name || "User Name" }}</div>
          </div>
          <div class="row m-0">
            <div class="title">Email:</div>
            <div class="content">{{ walker.email }}</div>
          </div>
          <div class="row m-0">
            <div class="title">Date Joined:</div>
            <div class="content">{{ walker.created_at | moment }}</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h1 class="mt-4">Owner Details</h1>
        <div class="user-details mt-3">
          <div class="row m-0">
            <div class="title">Name:</div>
            <div class="content">{{ owner.name || "User Name" }}</div>
          </div>
          <div class="row m-0">
            <div class="title">Email:</div>
            <div class="content">{{ owner.email }}</div>
          </div>
          <div class="row m-0">
            <div class="title">Date Joined:</div>
            <div class="content">{{ owner.created_at | moment }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <h1 class="mt-4">Bank Details</h1>
        <div class="user-details mt-3">
          <div v-if="walker.user_bank_card === null">
            No Bank Details Added
          </div>
          <div v-else>
            <div class="row m-0">
              <div class="title">Account Holder Name:</div>
              <div class="content">{{ walker.user_bank_card.accountholder_name || "-" }}</div>
            </div>
            <div class="row m-0">
              <div class="title">Bank:</div>
              <div class="content">{{ walker.user_bank_card.bank || "-" }}</div>
            </div>
            <div class="row m-0">
              <div class="title">Clearing:</div>
              <div class="content">{{ walker.user_bank_card.clearing || "-"}}</div>
            </div>
            <div class="row m-0">
              <div class="title">Account Number:</div>
              <div class="content">{{ walker.user_bank_card.account_number || "-" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  props: {
    walker: {
      type: Object,
    },
    owner: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      users: [],
    };
  },
  methods: {
    getAllUsers: function () {
      axios
        .get(
            "/admin/all-users?access_token=" +
            localStorage.getItem("access_token")
        )
        .then(
          (result) => {
            this.users = result.data.users;
          }, () => {
          }
        );
    },
    goToUser: function (user) {
      this.$router.push({ name: "userProfile", params: { user: user } });
    },
  },
  filters: {
    moment: function (date) {
      let d = moment(date);
      return d.isValid() ? moment(date).format("MMMM DD, YYYY") : "N/A";
    },
  },
  mounted() {
    this.getAllUsers();
  },
};
</script>

<style lang="scss" scoped>
.entry-content {
  padding: 75px 25px 25px 50px;
}

.user-details {
  .title {
    font-weight: bold;
    margin-right: 7px;
    margin-bottom: 5px;
  }
}

.pet-profile {
  background-color: white;
  margin-bottom: 25px;
  border-radius: 5px;
  padding: 7px;

  .title {
    font-weight: bold;
    margin-right: 7px;
    margin-bottom: 5px;
  }
}
</style>
