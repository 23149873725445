<template>
    <div class="background" :style="backgroundStyle">
      <slide-y-up-transition>
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
</template>
<script>
  import { SlideYUpTransition } from 'vue2-transitions'

  export default {
    name: 'auth-layout',
    components: {
      SlideYUpTransition
    },
    data() {
      return {
        year: new Date().getFullYear(),
        showMenu: false,
        backgroundStyle: {
          paddingLeft: 0,
          backgroundPosition: 'top left'
        }
      }
    },
    methods: {
    
    },
    mounted: function() {
      
    }
  }
</script>
<style>
.background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f2f0eb;
}
</style>
