<template>
  <div class="entry-content">
    <h1>Blocked User List</h1>

    <div class="table-header mt-4 pb-2">
      <div class="row">
        <div class="text col-2">Name</div>
        <div class="text col-3">Email</div>
        <div class="text col-2">Action</div>
        <div class="text col-2">Action Date</div>
      </div>
    </div>
    <div class="table-content mb-3">
      <div
        class="user"
        v-for="user in users"
        :key="user.id"
      >
        <div class="row">
          <div class="col-2">{{ user.reported_user.name || "User Name" }}</div>
          <div class="col-3">{{ user.reported_user.email }}</div>
          <div class="col-2">{{ user.action_result }}</div>
          <div class="col-2">{{ user.updated_at.slice(0,10) }}</div>
        </div>
        <div class="divider-line"></div>
      </div>
    </div>
    <pagination :total="totalPages" @fetchData="getAllUsers" v-if="totalPages > 1" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "../components/Pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      users: [],
      totalPages: 1,
    };
  },
  methods: {
    getAllBlockedUsers: function () {
      axios
        .get(
            "admin/reported-users?access_token=" +
            localStorage.getItem("access_token")
        )
        .then(
          (result) => {
            this.users = result.data.reports;
            this.totalPages = result.data.users.last_page;
          }, () => {
          }
        );
    },
  },
  mounted() {
    this.getAllBlockedUsers();
  },
};
</script>

<style lang="scss" scoped>
.entry-content {
  padding: 75px 25px 25px 50px;
}

.table-header {
  border-bottom: solid 1px black;

  .text {
    font-weight: bold;
  }
}

.user .row {
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.divider-line {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}
</style>
