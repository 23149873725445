import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'users',
      component: DashboardLayout,
      children: [
        {
          path: '/users',
          name: 'users',
          component: () => import(/* webpackChunkName: "demo" */ './views/Users.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/blockedUsers',
          name: 'blockedUsers',
          component: () => import(/* webpackChunkName: "demo" */ './views/BlockedUsers.vue'),
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/userProfile',
          name: 'userProfile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/paymentProfile',
          name: 'paymentProfile',
          component: () => import(/* webpackChunkName: "demo" */ './views/PaymentToDetail'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/',
      redirect: 'alerts',
      component: DashboardLayout,
      children: [
        {
          path: '/alerts',
          name: 'alerts',
          component: () => import(/* webpackChunkName: "demo" */ './views/Alerts.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/',
      redirect: 'faq',
      component: DashboardLayout,
      children: [
        {
          path: '/faq',
          name: 'faq',
          component: () => import(/* webpackChunkName: "demo" */ './views/Faq.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/',
      redirect: 'payments',
      component: DashboardLayout,
      children: [
        {
          path: '/payments',
          name: 'payments',
          component: () => import(/* webpackChunkName: "demo" */ './views/Payments.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/',
      redirect: 'settings',
      component: DashboardLayout,
      children: [
        {
          path: '/settings',
          name: 'settings',
          component: () => import(/* webpackChunkName: "demo" */ './views/PasswordChange.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        {
          path: '/password-forget',
          name: 'password-forget',
          component: () => import(/* webpackChunkName: "demo" */ './views/PasswordForget.vue')
        },
        {
          path: '/password-change',
          name: 'password-change',
          component: () => import(/* webpackChunkName: "demo" */ './views/PasswordChange.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: 'reset',
      component: AuthLayout,
      children: [
        {
          path: '/password-reset',
          name: 'password-reset',
          component: () => import(/* webpackChunkName: "demo" */ './views/PasswordReset.vue')
        },
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const auth = localStorage.getItem("access_token");

    if (!auth) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
