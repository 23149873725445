<template>
  <div class="entry-content">
    
    <div class="mt-4 d-flex justify-content-between">
      <h1 class="mb-0">User List</h1>
      <a @click="exportUsers()" class="btn btn-primary ml-3 text-white">Export</a>
    </div>
    <div class="mt-4 d-flex">
        <input type="text" v-model="inputSearchText"  placeholder="search" class="mr-6 form-control col-2"/>
        <input type="date" v-model="inputSearchDateStart" class="mr-2 form-control col-2"  name="start_date_join" ref="start_date_join"/>
        <span class="px-2 col-form-label">&sim;</span>
        <input  type="date" v-model="inputSearchDateEnd" class="mx-2 form-control col-2" v-validate="'after:start_date_join'"  name="end_date_join"/>
        <button type="submit" class="mx-2 btn btn-success text-white" @click="getAllUsers()">Search</button>
    </div>
    <div class="d-flex">
        <span class="col-2 mr-6"></span>
        <span v-show="errors.has('end_date_join')" class="mr-2 col-4 help is-danger text-center">{{ errors.first('end_date_join') }}</span>
    </div>
    <div class="table-header mt-4 pb-2">
      <div class="row">
        <div class="text col-2">Name</div>
        <div class="text col-3">Email</div>
        <div class="text col-3">Date Joined</div>
        <div class="text col-2">Pet Profile</div>
        <div class="text col-2">Certify</div>
        <div class="text col-2">Action</div>
      </div>
    </div>
    <div class="table-content mb-3">
      <div
        class="user"
        v-for="user in users"
        :key="user.id"
       
      >
        <div class="row">
          <div class="col-2" @click="goToUser(user)">{{ user.name || "User Name" }}</div>
          <div class="col-3" @click="goToUser(user)">{{ user.email }}</div>
          <div class="col-3" @click="goToUser(user)">{{ user.created_at | moment }}</div>
          <div class="col-2" @click="goToUser(user)">{{ user.pets.length }}</div>
          <div class="col-2"><input type="checkbox" :checked="user.certify == 1" @change="onChangeCertify($event,user.id)"/></div>
          <div class="col-2" @click="goToUser(user)"></div>
        </div>
        <div class="divider-line"></div>
      </div>
    </div>
    <pagination :total="totalPages" @fetchData="getAllUsers" v-if="totalPages > 1" />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import pagination from "../components/Pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      users: [],
      totalPages:1,
      certify : null,
      inputSearchText : '',
      inputSearchDateStart : '',
      inputSearchDateEnd : '',
    };
  },
  methods: {
    getAllUsers: function (page) {
      axios
        .get(
            "admin/all-users?page=" +
            page +
            "&access_token=" +
            localStorage.getItem("access_token") +  "&search=" + this.inputSearchText 
            + "&date_start=" + this.inputSearchDateStart 
            + "&date_end=" + this.inputSearchDateEnd 
        ).then(
          (result) => {
            this.users = result.data.users.data;
            this.totalPages = result.data.users.last_page;
          }, () => {
          }
        );
    },
    onChangeCertify(event, IdUser) {
      var data = new FormData();
      var certify = 0;
      if(event.target.checked) {
          certify = 1;
      }
      data.append('certify', certify);
      axios.post(
          "/admin/user/change-certify/" + IdUser +
          "?access_token=" +localStorage.getItem("access_token"), data
      ).then(
        (result) => {
          this.$noty.success(result.data.message);
        }, () => {
         
        }
      );
    },
    goToUser: function (user) {
      this.$router.push({ name: "userProfile", params: { user: user } });
    },

    exportUsers() {
      axios.get(
        "/admin/user/export?access_token=" +localStorage.getItem("access_token") + "&search=" + this.inputSearchText 
        + "&date_start=" + this.inputSearchDateStart 
        + "&date_end=" + this.inputSearchDateEnd 
      ).then(
        (result) => {
          var fileLink = document.createElement('a');
          fileLink.href = result.data.file;
          fileLink.setAttribute('download', 'export_users.csv');
          document.body.appendChild(fileLink);
          fileLink.click();
        
          this.$noty.success(result.data.message);
        }, () => {
         
        }
      );
    },
  },
  filters: {
    moment: function (date) {
      let d = moment(date);
      return d.isValid() ? moment.utc(date).format("MMMM DD, YYYY") : "N/A";
    },
  },
  mounted() {
    this.getAllUsers(1);
  },
};
</script>

<style lang="scss" scoped>
.entry-content {
  padding: 75px 25px 25px 50px;
}

.table-header {
  border-bottom: solid 1px black;

  .text {
    font-weight: bold;
  }
}

.user .row {
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.divider-line {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}
</style>
