<template>
  <div class="entry-content">
    <h1>FAQ</h1>
    <div class="btn btn-primary mt-4" @click="showAddNewModal">Add new FAQ</div>
    <div class="table-header mt-3 pb-2">
      <div class="row">
        <div class="text col-3">Question</div>
        <div class="text col-3">Answer</div>
        <div class="text col-1">Language</div>
        <div class="text col-3 offset-1">Action</div>
      </div>
    </div>
    <div class="table-content mb-3">
      <div class="user" v-for="faq in faqs" :key="faq.id">
        <div class="row">
          <div class="col-3">{{ faq.question }}</div>
          <div class="col-3">{{ faq.answers }}</div>

          <div class="col-1">{{ faq.language }}</div>
          <div class="col-2 offset-1">
            <div class="d-flex flex-row">
              <div class="btn btn-success" @click="showUpdateNewModal(faq)">Edit</div>
              <div class="btn btn-danger" @click="showDeleteModalFunction(faq.id)">
                Delete
              </div>
            </div>
          </div>
        </div>
        <div class="divider-line"></div>
      </div>
    </div>
    <pagination
      :total="totalPages"
      @fetchData="getAllUsers"
      v-if="totalPages > 1"
    />

    <modal :show="showModal" @close="showModal = false">
      <h1>{{ modal.title }}</h1>
      <h2 class="mb-0 mt-3">Question</h2>
      <textarea
        class="w-100"
        name="section"
        placeholder="Question"
        type="text"
        v-model="modal.question"
      />
      <h2 class="mb-0 mt-3">Answer</h2>
      <textarea
        class="w-100"
        name="section"
        placeholder="Answer"
        type="text"
        v-model="modal.answers"
      />
      <div>
        <input checked name="language" type="radio" value="EN" id="EN" v-model="modal.language"/>
        <label for="EN">English</label>
      </div>
      <div>
        <input type="radio" name="language" value="SE" id="SE" v-model="modal.language"/>
        <label for="SE">Swedish</label>
      </div>
      <div class="w-100 d-flex flex-column align-items-end">
        <div class="error-message text-danger font-weight-bold">
          {{ modal.errorMessage }}
        </div>
        <div class="buttons d-flex">
          <div class="btn btn-secondary mt-3 mr-3" @click="showModal = false">
            Cancel
          </div>
          <div
            class="btn btn-primary mt-3 justify-content-center d-flex"
            @click="addNewOrUpdateFaq"
            style="width: 200px"
          >
            <div v-if="modal.loading" class="loader"></div>
            <span class="font-weight-bold" v-else>{{ modal.title }}</span>
          </div>
        </div>
      </div>
    </modal>

    <modal :show="showDeleteModal" @close="showDeleteModal = false">
      <div class="d-flex flex-column align-items-center">
        <h2>Are you sure you want to delete?</h2>
        <h3>Deletion cannot be reverted</h3>
        <div class="buttons">
          <div class="buttons d-flex">
            <div class="btn btn-secondary mt-3 mr-3" @click="showDeleteModal = false">
              Cancel
            </div>
            <div
              class="btn btn-danger mt-3 justify-content-center d-flex"
              @click="deleteFaq(idOfQuestionToDelete)"
              style="width: 200px"
            >
              <div v-if="modal.loading" class="loader"></div>
              <span class="font-weight-bold" v-else>Confirm Delete</span>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import pagination from "../components/Pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      users: [],
      totalPages: 1,
      faqs: [],
      existingSections: [],
      showModal: false,
      showDeleteModal: false,
      idOfQuestionToDelete: Number,
      modal: {
        type: String,
        title: String,
        section: "",
        language: "",
        question: "",
        answers: "",
        sectionType: "existingSection",
        loading: false,
        errorMessage: "",
        id: Number,
      },
    };
  },
  methods: {

    getAllFaq: function () {
      axios.get(
          "/faq?access_token=" +
          localStorage.getItem("access_token")
      ).then(
        (result) => {
          this.faqs = result.data.faq;
        }, () => {
        }
      );
    },
    deleteFaq: function (id) {
      this.modal.loading = true;
      axios
        .delete(
            "admin/faq/delete/" +
            id +
            "?access_token=" +
            localStorage.getItem("access_token")
        )
        .then(
          () => {
            this.getAllFaq();
            this.modal.loading = false;
            this.showDeleteModal = false;
          }, () => {
            this.modal.loading = false;
            this.showDeleteModal = false;
          }
        );
    },
    addNewOrUpdateFaq: function () {
      this.modal.loading = true;
      const data = {};
      data.question = this.modal.question;
      data.answers = this.modal.answers;
      data.language = this.modal.language;
      if (this.modal.id === null) {
        axios({
          method: "POST",
          url:
            "admin/faq/add?access_token=" +
            localStorage.getItem("access_token"),
          data: data,
        }).then(
          () => {
            this.getAllFaq();
            this.modal.loading = false;
            this.showModal = false;
          },
          (error) => {
            this.modal.loading = false;
            this.modal.errorMessage = error.response.data.message;
          }
        );
      } else {
        axios({
          method: "POST",
          url:
            "admin/faq/update/" +
            this.modal.id +
            "?access_token=" +
            localStorage.getItem("access_token"),
          data: data,
        }).then(
          () => {
            this.getAllFaq();
            this.modal.loading = false;
            this.showModal = false;
          },
          (error) => {
            this.modal.loading = false;
            this.modal.errorMessage = error.response.data.message;
          }
        );
      }
    },
    showAddNewModal: function () {
      this.showModal = true;
      this.modal.type = "add";
      this.modal.language = "EN";
      this.modal.title = "Add new FAQ";
      this.modal.question = "";
      this.modal.answers = "";
      this.modal.errorMessage = "";
      this.modal.id = null;
    },
    showUpdateNewModal: function (faq) {
      this.showModal = true;
      this.modal.type = "update";
      this.modal.title = "Update existing FAQ";
      this.modal.question = faq.question;
      this.model.language = faq.language;
      this.modal.answers = faq.answers;
      this.modal.errorMessage = "";
      this.modal.id = faq.id;
    },
    showDeleteModalFunction: function (id) {
      this.idOfQuestionToDelete = id;
      this.showDeleteModal = true;
    },
  },
  filters: {
    moment: function (date) {
      let d = moment(date);
      return d.isValid() ? moment(date).format("MMMM DD, YYYY") : "N/A";
    },
  },
  mounted() {
    this.getAllFaq();
  },
};
</script>

<style lang="scss" scoped>
.entry-content {
  padding: 75px 25px 25px 50px;
}

.table-header {
  border-bottom: solid 1px black;

  .text {
    font-weight: bold;
  }
}

.user .row {
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.divider-line {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}
</style>
