<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Users',
            custom_icon: '/img/icons/nav-user.svg',
            path: '/users'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Blocked Users',
            custom_icon: '/img/icons/nav-user.svg',
            path: '/blockedUsers'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Alerts',
            custom_icon: '/img/icons/chat.svg',
            path: '/Alerts'
          }"
        />
        <sidebar-item
          :link="{
            name: 'FAQ',
            custom_icon: '/img/icons/chat.svg',
            path: '/Faq'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Payments',
            custom_icon: '/img/icons/chat.svg',
            path: '/Payments'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Settings',
            custom_icon: '/img/icons/settings.svg',
            path: '/settings'
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
<style lang="scss">
</style>
